<script>
import _get from 'lodash/get';
import APIStore from '@app/services/API/Store';

export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      initialNoteText: _get(this, 'customer.crm_notes.GENERAL.text'),
      note: {
        type: 'GENERAL',
        text: _get(this, 'customer.crm_notes.GENERAL.text'),
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
    handle() {
      this.isLoading = true;
      const body = { ...this.note };

      APIStore.addCustomerNote(
        this.authStore.uuid,
        this.customer.uuid,
        body,
      )
        .then((data) => {
          this.initialNoteText = this.note.text;
          this.$store.commit('customers/setGeneralNote', data);
          this.$showMessage.success();
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <section class="card is-relative has-background-warning-light">
    <header class="card-header">
      <h2 class="card-header-title">
        Carnet de notes privé sur {{ customer.firstname }}
        <AppFeatureBadge class="ml-2" position="is-right" />
      </h2>
    </header>
    <div class="card-content">
      <GoProWrapper planLock="EXPERT" :hasSep="false">
        <form class="block" @submit.prevent="dataIsValid(isLoading) && handle()">
          <b-field label="Rédigez vos notes privées">
            <b-input
              type="textarea"
              v-model="note.text"
              maxlength="5000"
              expanded
            />
          </b-field>
          <div class="field has-text-right">
            <b-button
              type="is-primary"
              native-type="submit"
              :disabled="initialNoteText == note.text"
            >
              Enregistrer
            </b-button>
          </div>
        </form>
      </GoProWrapper>
    </div>

    <b-loading v-if="isLoading" :is-full-page="false" active />
  </section>
</template>
