<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
    api: {
      type: Function,
      required: true,
    },
    apiError: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      editedCustomer: {
        firstname: this.customer.firstname,
        lastname: this.customer.lastname,
        email: this.customer.email,
        phone: this.customer.phone,
        birthday: this.customer.birthday && new Date(this.customer.birthday),
      },
    };
  },
  methods: {
    handle() {
      this.isLoading = true;
      const data = { ...this.editedCustomer };

      if (data.birthday) {
        data.birthday = this.$moment(data.birthday).format('YYYY-MM-DD');
      }

      this.api(data)
        .then(() => this.$emit('done'))
        .catch(this.apiError || ((error) => this.$errorHandlers.axios(error)))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <AppCardModal
    tag="form"
    @submit.prevent="dataIsValid(isLoading) && handle()"
  >
    <template #title>
      Modifier les informations
    </template>
    <template #body>
      <div class="columns is-multiline">
        <div class="column is-6">
          <b-field label="Prénom">
            <b-input
              v-model="editedCustomer.firstname"
              maxlength="128"
              :has-counter="false"
              required
            />
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Nom">
            <b-input
              v-model="editedCustomer.lastname"
              maxlength="128"
              :has-counter="false"
            />
          </b-field>
        </div>
        <div class="column is-12">
          <b-field label="Adresse e-mail">
            <b-input v-model="editedCustomer.email" type="email" required />
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Téléphone">
            <b-input v-model="editedCustomer.phone" type="tel" />
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Date de naissance">
            <b-datepicker
              v-model="editedCustomer.birthday"
              position="is-top-left"
              append-to-body
            />
          </b-field>
        </div>
      </div>
    </template>
    <template #foot>
      <b-button
        type="is-primary"
        native-type="submit"
        :loading="isLoading"
      >
        Modifier
      </b-button>
    </template>
  </AppCardModal>
</template>
