<script>
import CustomerEditForm from '@app/components/CustomerEditForm.vue';

export default {
  components: {
    CustomerEditForm,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    titleTag: {
      type: [String, Object],
      default: 'h2',
    },
  },
  data: () => ({ isEditing: false }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
    updateCustomer(data) {
      return this.$store.dispatch('customers/update', {
        storeUUID: this.authStore.uuid,
        customerUUID: this.customer.uuid,
        data,
      });
    },
  },
};
</script>

<template>
  <section>
    <div class="card">
      <div class="card-image">
        <figure class="image is-2by1 bbottom-grey-light">
          <img
            v-if="customer.picture"
            class="h-full w-full ofit-contain has-background-dark"
            :src="`${$env.apiResourcesURL}/${customer.picture}`"
            :alt="`${customer.firstname} ${customer.lastname}`"
          >
          <div v-else class="pabsolute-0 flex-center-center">
            <b-icon icon="user" class="is-size-1" />
          </div>
        </figure>
      </div>
      <div class="card-content p-3">
        <div class="has-text-centered break-words">
          <component
            :is="titleTag"
            class="is-size-5 has-text-weight-medium has-text-black"
          >
            {{ customer.firstname }}
            <br>
            <template v-if="!!customer.lastname">
              {{ customer.lastname }}
            </template>
            <template v-else>
              (aucun nom renseigné)
            </template>
          </component>
          <div class="mt-3 is-size-8 has-background-light bradius-4">
            <b-tooltip class="w-full bbottom-grey-light p-3">
              <template #content>
                Inscription le <br>
                {{ customer.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </template>
              <div class="is-flex w-full is-justify-content-space-between">
                <strong>Inscrit</strong>
                {{ customer.created_at | momentFromUTC | moment('from') }}
              </div>
            </b-tooltip>
            <br>
            <b-tooltip class="w-full p-2" :active="!!customer.last_login_at">
              <template #content>
                Dernière connexion le <br>
                {{ customer.last_login_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </template>
              <template v-if="customer.last_login_at">
                <div class="is-flex w-full is-justify-content-space-between">
                  <strong>Connecté</strong>
                  {{ customer.last_login_at | momentFromUTC | moment('from') }}
                </div>
              </template>
              <template v-else>
                Jamais connecté
              </template>
            </b-tooltip>
          </div>
          <div class="mt-3">
            <b-button
              type="is-primary"
              expanded
              @click="isEditing = true"
            >
              Modifier les informations
            </b-button>
          </div>
        </div>
      </div>
      <div class="card-content px-0 pt-3 pb-1">
        <table class="table is-fullwidth is-size-8" style="table-layout: fixed;">
          <tbody>
            <tr>
              <td colspan="2" class="has-text-weight-medium">
                <div class="flex-between-center">
                  <div class="has-text-left">
                    E-mail :
                  </div>
                  <div class="has-text-right break-words">
                    <a :href="`mailto:${customer.email}`">
                      {{ customer.email }}
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="has-text-weight-medium w-50p">
                E-mails marketing :
              </td>
              <td class="has-text-right break-words">
                <template v-if="customer.accept_marketing">
                  Acceptés
                </template>
                <template v-else>
                  Refusés
                </template>
              </td>
            </tr>
            <tr>
              <td class="has-text-weight-medium w-50p">
                Téléphone :
              </td>
              <td class="has-text-right break-words">
                <a v-if="!!customer.phone" :href="`tel:${customer.phone}`">
                  {{ customer.phone }}
                </a>
                <template v-else>
                  ---
                </template>
              </td>
            </tr>
            <tr>
              <td class="has-text-weight-medium w-50p">
                Date de naissance :
              </td>
              <td class="has-text-right break-words">
                <template v-if="!!customer.birthday">
                  {{ customer.birthday | moment('DD/MM/YYYY') }}
                </template>
                <template v-else>
                  ---
                </template>
              </td>
            </tr>
            <!-- <tr>
              <td class="has-text-weight-medium w-50p">
                Adresse :
              </td>
              <td class="has-text-right break-words">
                <template v-if="!!customer.billing_address">
                  {{ customer.billing_address.address_line_1 }}
                  <template v-if="!!customer.billing_address.address_line_2">
                    {{ customer.billing_address.address_line_2 }}
                  </template>
                </template>
                <template v-else>
                  ---
                </template>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>

    <b-modal v-if="isEditing" :active.sync="isEditing" has-modal-card>
      <CustomerEditForm
        :api="updateCustomer"
        :customer="customer"
        @done="isEditing = false"
      />
    </b-modal>
  </section>
</template>
