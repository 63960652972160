<script>
import EventBus from '@shared/services/EventBus';
import CustomerTagsForm from '@app/components/CustomerTagsForm.vue';
import CustomerStatsList from '@app/components/CustomerStatsList.vue';
import CustomerProfileCard from '@app/components/CustomerProfileCard.vue';
import CustomerLoginHistory from '@app/components/CustomerLoginHistory.vue';
import CustomerGeneralNoteForm from '@app/components/CustomerGeneralNoteForm.vue';
import CustomerPrivateDataForm from '@app/components/CustomerPrivateDataForm.vue';
import CustomerHistoryNotesForm from '@app/components/CustomerHistoryNotesForm.vue';
import CustomerPageTrainingsList from '@app/components/CustomerPageTrainingsList.vue';

export default {
  components: {
    CustomerTagsForm,
    CustomerStatsList,
    CustomerProfileCard,
    CustomerLoginHistory,
    CustomerGeneralNoteForm,
    CustomerPrivateDataForm,
    CustomerHistoryNotesForm,
    CustomerPageTrainingsList,
  },
  data: () => ({
    isFetching: false,
  }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    currentCustomer() {
      return this.$store.state.customers.current;
    },
  },
  methods: {
    load() {
      if (this.isFetching) {
        return;
      }

      this.isFetching = true;
      this.$store.dispatch('customers/find', {
        storeUUID: this.authStore.uuid,
        customerUUID: this.$route.params.uuid,
      })
        .catch((error) => {
          if (error.response.data.code === 'ENTITY_NOT_FOUND') {
            this.$showMessage.error(this.$t('messages.customer_not_found'));
            this.$router.push({ name: 'customers' });
            return;
          }

          this.$errorHandlers.axios(error);
        })
        .finally(() => (this.isFetching = false));
    },
  },
  watch: {
    '$route.params.uuid': function() {
      this.load();
    },
  },
  created() {
    this.load();
    EventBus.on('customer-page-reload', this.load);
  },
  destroyed() {
    EventBus.off('customer-page-reload', this.load);
  },
};
</script>

<template>
  <div>
    <header class="mb-5">
      <router-link :to="$routeReferrer || {name: 'customers'}">
        <b-icon size="is-small" icon="chevron-left" />
        Retour aux apprenants
      </router-link>
    </header>
    <div class="columns">
      <div class="column is-narrow w-full mw-360">
        <b-skeleton v-if="isFetching" height="600" />
        <template v-else>
          <CustomerProfileCard
            :customer="currentCustomer"
            titleTag="h1"
          />
          <CustomerPrivateDataForm
            class="mt-6"
            :customer="currentCustomer"
          />
        </template>
      </div>
      <div class="column">
        <b-skeleton v-if="isFetching" height="600" />
        <template v-else>
          <CustomerStatsList :customer="currentCustomer" />
          <CustomerTagsForm class="mt-5" :customer="currentCustomer" />
          <CustomerPageTrainingsList
            class="mt-8"
            @trainings-associated="load()"
            @training-delete="load()"
          />
          <CustomerHistoryNotesForm
            class="mt-8"
            :customer="currentCustomer"
          />
          <CustomerGeneralNoteForm
            class="mt-8"
            :customer="currentCustomer"
          />
          <CustomerLoginHistory
            class="mt-8"
            :customer="currentCustomer"
          />
        </template>
      </div>
    </div>

    <router-view />
  </div>
</template>
