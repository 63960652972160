<script>
import utilsMixin from '@shared/mixins/utils';
import APIStore from '@app/services/API/Store';
import ProgressCircle from '@app/components/ProgressCircle.vue';
import CustomerTrainingCard from '@app/components/CustomerTrainingCard.vue';
import CustomerTrainingTimeProgessionCard from '@app/components/CustomerTrainingTimeProgessionCard.vue';
import CustomerListItemDropdown from '@app/components/CustomerListItemDropdown.vue';
import CustomerTrainingTransactions from '@app/components/CustomerTrainingTransactions.vue';

export default {
  mixins: [utilsMixin],
  components: {
    ProgressCircle,
    CustomerListItemDropdown,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    customerTraining: {
      type: Object,
      required: true,
    },
  },
  computed: {
    store() {
      return this.$store.getters['auth/store'];
    },
    typeClass() {
      return {
        'is-primary': this.customerTraining.customer_type === 'STUDENT',
        'is-dark': this.customerTraining.customer_type === 'BLOCKED',
        'is-warning': this.customerTraining.customer_type === 'ABANDONED_CART',
      };
    },
    rootTransaction() {
      return this.customerTraining.payment_transactions[0];
    },
    paidTransactions() {
      const [transaction] = this.customerTraining.payment_transactions;

      if (!transaction) {
        return null;
      }

      const reducer = (prev, curr) => {
        if (curr.children.length) {
          return prev + curr.children.reduce(reducer, 0);
        }

        return prev + (curr.status === 'PAID' ? 1 : 0);
      };

      if (transaction.children.length) {
        return transaction.children.reduce(reducer, 0);
      }

      return transaction.status === 'PAID' ? 1 : 0;
    },
    paidAmount() {
      const [transaction] = this.customerTraining.payment_transactions;

      if (!transaction) {
        return null;
      }

      const reducer = (prev, curr) => {
        if (curr.children.length) {
          return prev + curr.children.reduce(reducer, 0);
        }

        return prev + (curr.status === 'PAID' ? curr.amount : 0);
      };

      if (transaction.children.length) {
        return transaction.children.reduce(reducer, 0);
      }

      return transaction.status === 'PAID' ? transaction.amount : 0;
    },
    totalTransactions() {
      const [transaction] = this.customerTraining.payment_transactions;

      if (!transaction) {
        return null;
      }

      return transaction.children.length || 1;
    },
    lastTransaction() {
      const [transaction] = this.customerTraining.payment_transactions;

      if (!transaction) {
        return null;
      }

      return transaction.children[transaction.children.length - 1];
    },
  },
  methods: {
    showProgress(training, customer) {
      const loader = this.$buefy.loading.open();
      APIStore.getCustomerTraining(this.store.uuid, customer.uuid, training.uuid)
        .then(({ data }) => this.$buefy.modal.open({
          component: CustomerTrainingCard,
          parent: this,
          width: '768px',
          props: { customer, customerTraining: data },
        }))
        .finally(() => loader.close());
    },
    showLogs(training, customer) {
      const loader = this.$buefy.loading.open();
      APIStore.getCustomerTraining(this.store.uuid, customer.uuid, training.uuid)
        .then(({ data }) => this.$buefy.modal.open({
          component: CustomerTrainingTimeProgessionCard,
          parent: this,
          width: '768px',
          props: { customer, customerTraining: data },
        }))
        .finally(() => loader.close());
    },
    showTransactions(training, customer) {
      const transactions = training.payment_transactions;
      this.$buefy.modal.open({
        component: CustomerTrainingTransactions,
        parent: this,
        width: '768px',
        props: { transactions, training, customer },
      });
    },
    showExpiration(customerTraining) {
      return customerTraining.expiration
        && this.$moment().isBefore(customerTraining.expiration)
        && customerTraining.customer_type === 'STUDENT';
    },
  },
};
</script>

<template>
  <article class="box" :class="{ 'is-danger': customerTraining.deleted_at }">
    <div class="columns is-multiline">
      <div class="column">
        <h3 class="title is-5">
          <router-link :to="{ name: 'training', params: { uuid: customerTraining.uuid } }">
            {{ customerTraining.name }}
          </router-link>
        </h3>
      </div>
      <div v-if="!customerTraining.deleted_at" class="column is-narrow">
        <CustomerListItemDropdown :customer="customer" :training="customerTraining"
          @show-progression="showProgress(customerTraining, customer)" @update-status="$emit('customer-update', $event)"
          @delete="$emit('delete')" />
      </div>
      <div class="column is-12">
        <p class="is-size-8">
          <span v-if="customerTraining.enrolled_at" class="mr-3">
            Acquise le
            {{ customerTraining.enrolled_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
          </span>
          <span v-else class="mr-3">
            Inscription commencée le
            {{ customerTraining.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
          </span>
          <span v-if="!customerTraining.deleted_at && customerTraining.customer_type === 'BLOCKED'" class="tag mr-3"
            :class="typeClass" v-t="`customer.blocked.${customerTraining.blocked_reason}`" />
          <span v-else-if="!customerTraining.deleted_at" class="tag mr-3" :class="typeClass"
            v-t="`customer.type.${customerTraining.customer_type}`" />
          <b-tooltip class="mr-3" :label="$t(`customer.enrollment.${customerTraining.enrollment_type}_long`)">
            <span class="tag is-warning is-light has-text-weight-bold"
              v-t="`customer.enrollment.${customerTraining.enrollment_type}`" />
          </b-tooltip>
          <strong v-if="customerTraining.deleted_at" class="tag is-danger is-light is-medium mr-3"
            v-t="`customer.deleted_from_training`" />
        </p>
        <p v-if="customerTraining.deleted_at" class="mt-3 has-text-weight-bold is-size-8">
          Retiré de la formation le
          {{ customerTraining.deleted_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
        </p>
        <template v-if="!customerTraining.deleted_at">
          <p v-if="totalTransactions" class="mt-1 is-flex is-align-items-center is-size-8">
            <b-tooltip v-if="customerTraining.paid_price" :active="totalTransactions > 1" position="is-right">
              <template v-if="totalTransactions > 1" #content>
                Le dernier paiement sera effectué le
                {{ lastTransaction.payment_date | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </template>
              Payée
              <template v-if="totalTransactions > 1">
                ({{ paidTransactions }} paiement{{ paidTransactions > 1 ? 's' : '' }}
                sur {{ totalTransactions }})
              </template>
              <strong>
                {{ paidAmount | formatPrice }}
                <template v-if="totalTransactions > 1">
                  / {{ rootTransaction.amount | formatPrice }}
                </template>
              </strong>
              <template v-if="rootTransaction.discount_code_applied">
                avec le code promo {{ rootTransaction.discount_code_applied }}
              </template>
            </b-tooltip>
            <b-tooltip v-if="totalTransactions > 1">
              <template #content>
                <span v-t="'buttons.see_details'" />
              </template>
              <b-button type="is-text" icon-left="eye" @click="showTransactions(customerTraining, customer)" />
            </b-tooltip>
          </p>
          <p v-if="customerTraining.billing_address" class="mt-2 is-size-8">
            <strong class="has-text-weight-medium">Adresse de facturation : </strong> {{
              customerTraining.billing_address['full_address'] }}
          </p>

          <p v-if="customerTraining.from_bundle" class="mt-2 is-flex is-align-items-center is-size-8">
            Formation acquise via le pack
            <strong class="ml-1">
              <router-link v-if="!customerTraining.from_bundle.deleted_at" :to="{ name: 'bundle', params: { uuid: customerTraining.from_bundle.uuid } }" class="ml-1">
                {{ customerTraining.from_bundle.name }}
              </router-link>
              <span v-else>
                {{ customerTraining.from_bundle.name }} (pack supprimé)
              </span>
            </strong>
          </p>
          <p class="mt-2 is-flex is-align-items-center is-size-8">
            Temps passé sur cette formation
            <strong class="ml-1">
              {{ customerTraining.total_duration_in_second | formatSeconds }}
            </strong>
            <b-tooltip :label="$t('buttons.see_details')">
              <b-button type="is-text" icon-left="eye" @click="showLogs(customerTraining, customer)" />
            </b-tooltip>
          </p>
          <p v-if="showExpiration(customerTraining)" class="mt-2 is-size-8">
            L'accès à cette formation expire automatiquement
            <b-tooltip>
              <template #content>
                le {{ customerTraining.expiration | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </template>
              <strong>
                le {{ customerTraining.expiration | momentFromUTC | moment('DD/MM/YY') }}
              </strong>
            </b-tooltip>
            <strong>
            </strong>
          </p>
        </template>
      </div>
      <div class="column is-12">
        <div class="p-3 border-grey-2 bradius-8 overflow-hidden">
          <div class="columns is-multiline has-text-centered is-size-8">
            <div class="column is-4">
              <h4 class="title is-7 mb-2">
                Inscription le
              </h4>
              <b-tooltip position="is-right">
                <template #content>
                  Inscription finalisée le
                  {{ customerTraining.enrolled_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
                </template>
                {{ customerTraining.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </b-tooltip>
            </div>
            <div class="column is-4">
              <h4 class="title is-7 mb-2">
                Commencée le
              </h4>
              <p v-if="customerTraining.started_at">
                {{ customerTraining.started_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </p>
              <p v-else>
                Pas commencée
              </p>
            </div>
            <div class="column is-4">
              <h4 class="title is-7 mb-2">
                Terminée le
              </h4>
              <p v-if="customerTraining.completed_at">
                {{ customerTraining.completed_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </p>
              <p v-else>
                Pas terminée
              </p>
            </div>
            <div class="column">
              <h4 class="title is-7 mb-2">
                Dernier accès
              </h4>
              <p v-if="customerTraining.last_activity_at">
                {{ customerTraining.last_activity_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </p>
              <p v-else>
                Aucun
              </p>
            </div>
            <div v-if="customerTraining.enrolled_at" class="column">
              <h4 class="title is-7 mb-2">
                Progression
              </h4>
              <a href="#" @click.prevent="showProgress(customerTraining, customer)">
                <b-tooltip :label="$t('customer.see_progression')">
                  <span class="is-flex is-align-items-center">
                    <ProgressCircle class="mr-2" :max="customerTraining.total_module_count"
                      :value="customerTraining.completed_module_count" :size="20" :borderSize="2" />
                    {{ customerTraining.completed_module_count }} / {{ customerTraining.total_module_count }}
                  </span>
                </b-tooltip>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
