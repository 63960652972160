<script>
import { required } from 'vuelidate/lib/validators';
import { generateId } from '@shared/services/Utils';
import APIStore from '@app/services/API/Store';

export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    newNote: {
      type: 'HISTORY',
      id: '',
      text: '',
    },
  }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    reversedNotes() {
      return [...this.customer.crm_notes.HISTORY].reverse();
    },
  },
  validations: {
    newNote: {
      text: {
        required,
      },
    },
  },
  methods: {
    removeNote(note) {
      const loader = this.$buefy.loading.open({ container: this.$el });

      APIStore.removeCustomerNote(
        this.authStore.uuid,
        this.customer.uuid,
        note.id,
      )
        .then((data) => {
          console.log(data);
          this.$store.commit('customers/removeHistoryNote', note);
        })
        .finally(() => loader.close());
    },
    handle() {
      this.isLoading = true;
      const body = {
        ...this.newNote,
        id: generateId(36),
      };

      APIStore.addCustomerNote(
        this.authStore.uuid,
        this.customer.uuid,
        body,
      )
        .then((data) => {
          this.$store.commit('customers/addHistoryNote', data);
          this.newNote.text = '';
          this.$nextTick(() => this.$v.$reset());
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <section class="card is-relative">
    <header class="card-header">
      <h2 class="card-header-title">
        Ajoutez des commentaires privés et datés
        <AppFeatureBadge class="ml-2" position="is-right" />
      </h2>
    </header>
    <div class="card-content">
      <GoProWrapper planLock="EXPERT" :hasSep="false">
        <form class="block" @submit.prevent="dataIsValid(isLoading) && handle()">
          <b-field
            label="Rédigez votre commentaire"
            v-bind="$getErrorProps($v.newNote.text, ['required'])"
          >
            <b-input type="textarea" rows="2" v-model="newNote.text" expanded />
          </b-field>
          <div class="field has-text-right">
            <b-button
              type="is-primary"
              native-type="submit"
              :loading="isLoading"
            >
              Ajouter
            </b-button>
          </div>
        </form>
        <ul>
          <li
            v-for="(note, k) in reversedNotes"
            :key="k"
            class="notification p-3"
          >
            <p class="is-size-9 has-text-dark is-italic">
              <span class="has-text-weight-medium">Posté par</span> {{ note.created_by_firstname }} {{ note.created_by_lastname[0] }} <span class="has-text-weight-medium">le</span> <time class="has-text-weight-medium" :datetime="note.created_at">{{ note.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}</time>
            </p>
            <p class="mt-2 wspace-preline">
              {{ note.text }}
            </p>
            <p class="mt-2 has-text-right">
              <b-button
                class="is-size-9 has-text-danger tdecoration-underline"
                type="is-text"
                @click="removeNote(note)"
              >
                Supprimer
              </b-button>
            </p>
          </li>
        </ul>
      </GoProWrapper>
    </div>
  </section>
</template>
