<script>
import { required } from 'vuelidate/lib/validators';
import APIStore from '@app/services/API/Store';

export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    hasFormOpen: false,
    newPrivateData: {
      key: '',
      value: '',
    },
  }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  validations: {
    newPrivateData: {
      key: {
        required,
      },
    },
  },
  methods: {
    onFormClose() {
      this.isEditing = false;
      this.hasFormOpen = false;
      this.newPrivateData.key = '';
      this.newPrivateData.value = '';
    },
    editPrivateData(key, privateData) {
      this.isEditing = true;
      this.hasFormOpen = true;
      this.newPrivateData.key = key;
      this.newPrivateData.value = privateData.value;
    },
    removePrivateData(privateDataKey) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: 'Attention !',
        message: 'Cette suppression est définitive',
        focusOn: 'cancel',
        onConfirm: () => {
          const loader = this.$buefy.loading.open({ container: this.$el });

          APIStore.addOrUpdateCustomerPrivateData(
            this.authStore.uuid,
            this.customer.uuid,
            { key: privateDataKey, value: null },
          )
            .then(({ store_feature_options, crm_private_data }) => {
              this.$store.commit('auth/setStoreFeatureOptions', store_feature_options);
              this.$store.commit('customers/setPrivateData', crm_private_data);
            })
            .finally(() => loader.close());
        },
      });
    },
    handle() {
      this.isLoading = true;
      const body = { ...this.newPrivateData };

      APIStore.addOrUpdateCustomerPrivateData(
        this.authStore.uuid,
        this.customer.uuid,
        body,
      )
        .then(({ store_feature_options, crm_private_data }) => {
          this.$store.commit('auth/setStoreFeatureOptions', store_feature_options);
          this.$store.commit('customers/setPrivateData', crm_private_data);

          this.newPrivateData.key = '';
          this.newPrivateData.value = '';
          this.hasFormOpen = false;
          this.isEditing = false;

          this.$nextTick(() => this.$v.$reset());
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <section class="card is-relative">
    <header class="card-header">
      <h2 class="card-header-title is-block">
        Données personnalisées
        <AppFeatureBadge class="ml-2" position="is-right" />
      </h2>
    </header>
    <div class="card-content p-3">
      <GoProWrapper planLock="EXPERT" :hasSep="false">
        <ul v-if="customer.crm_private_data" class="mb-6">
          <li
            v-for="(privateData, k) in customer.crm_private_data"
            :key="k"
            class="columns is-mobile bbottom-grey"
          >
            <div class="column">
              <b-tooltip position="is-right">
                <template #content>
                  <span class="is-block">
                    Ajoutée le {{ privateData.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
                  </span>
                  <span class="is-block mt-2">
                    Modifiée le {{ privateData.updated_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
                  </span>
                </template>
                <p class="title is-8 has-text-black70">{{ k }}</p>
              </b-tooltip>

              <p class="subtitle is-7">
                {{ privateData.value }}
              </p>
            </div>
            <div class="column is-narrow">
              <b-dropdown class="has-text-left" position="is-bottom-left">
                <template #trigger>
                  <b-button
                    type="is-text"
                    class="h-auto p-0 ml-3 has-text-black30"
                    icon-left="ellipsis-v"
                  />
                </template>
                <b-dropdown-item
                  @click="editPrivateData(k, privateData)"
                >
                  Modifier
                </b-dropdown-item>
                <b-dropdown-item
                  class="has-text-danger"
                  @click="removePrivateData(k)"
                >
                  Supprimer
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </li>
        </ul>
        <p v-else class="notification">
          Vous n'avez entré aucune donnée personnalisée pour le moment.
        </p>
        <p>
          <b-button
            type="is-primary"
            expanded
            @click="hasFormOpen = true"
          >
            Ajouter une donnée
          </b-button>
        </p>
        <b-modal
          v-if="hasFormOpen"
          :active.sync="hasFormOpen"
          hasModalCard
          :canCancel="false"
          @close="onFormClose"
        >
          <AppCardModal
            tag="form"
            @submit.prevent="dataIsValid(isLoading) && handle()"
          >
            <template #title>
              Ajouter une donnée
            </template>
            <template #body>
              <div class="field is-grouped">
                <div class="control is-expanded">
                  <b-field
                    v-bind="$getErrorProps($v.newPrivateData.key, ['required'])"
                  >
                    <b-input
                      v-model.trim="newPrivateData.key"
                      placeholder="Clé / Libellé"
                      maxlength="64"
                      :has-counter="false"
                      :disabled="isEditing"
                      expanded
                    />
                  </b-field>
                </div>
                <div class="control is-expanded">
                  <b-input
                    v-model.trim="newPrivateData.value"
                    placeholder="Valeur..."
                    maxlength="64"
                    :has-counter="false"
                    expanded
                  />
                </div>
              </div>
            </template>
            <template #foot>
              <b-button
                type="is-text"
                :disabled="isLoading"
                @click="onFormClose"
              >
                Annuler
              </b-button>

              <b-button
                type="is-primary"
                native-type="submit"
                :loading="isLoading"
              >
                Ajouter
              </b-button>
            </template>
          </AppCardModal>
        </b-modal>
      </GoProWrapper>
    </div>
  </section>
</template>
