<script>
import utilsMixin from '@shared/mixins/utils';
import CustomerTrainingCardItemLine from '@app/components/CustomerTrainingCardItemLine.vue';

export default {
  mixins: [utilsMixin],
  components: { CustomerTrainingCardItemLine },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    customerTraining: {
      type: Object,
      required: true,
    },
  },
  computed: {
    completedPercent() {
      const one = this.customerTraining;
      return Math.round((one.completed_module_count / one.total_module_count) * 100);
    },
  },
};
</script>

<template>
  <div class="box">
    <h1 class="title is-4 is-mobile-6">
      Logs de connexion
    </h1>
    <p class="mb-5">
      {{ customer.firstname }} a passé
      <strong>{{ customerTraining.total_duration_in_second | formatSeconds }}</strong>
      au total sur la formation <strong>{{ customerTraining.name }}</strong> :
    </p>
    <div class="mt-5">
      <ul
        v-for="one in customerTraining.training_items"
        :key="one.id"
        class="panel border-grey-2 mb-3 is-shadowless"
      >
        <template v-if="one.type == 'SECTION'">
          <li class="panel-block has-text-weight-bold is-uppercase">
            {{ one.name }}
          </li>
          <CustomerTrainingCardItemLine
            v-for="two in one.children"
            :key="two.id"
            tag="li"
            class="panel-block"
            :customer="customer"
            :customerTraining="customerTraining"
            :item="two"
            onlyShowTime
          />
        </template>
        <CustomerTrainingCardItemLine
          v-else tag="li"
          class="panel-block"
          :customer="customer"
          :customerTraining="customerTraining"
          :item="one"
          onlyShowTime
        />
      </ul>
    </div>
  </div>
</template>
