<script>
import utilsMixin from '@shared/mixins/utils';
import StatisticCard from '@shared/components/StatisticCard.vue';

export default {
  mixins: [utilsMixin],
  components: {
    StatisticCard,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totalDurationInSeconds() {
      return this.customer
        .customer_trainings
        .reduce(
          (prev, curr) => (prev + (curr.total_duration_in_second || 0)),
          0,
        );
    },
  },
};
</script>

<template>
  <ul class="columns is-multiline">
    <li class="column is-6 is-4-widescreen">
      <StatisticCard class="h-full">
        <template #title>
          Formation{{ customer.total_active_trainings > 1 ? 's' : '' }}
          acquise{{ customer.total_active_trainings > 1 ? 's' : '' }}
        </template>
        <template #default>
          {{ customer.total_active_trainings }}
        </template>
      </StatisticCard>
    </li>
    <li class="column is-6 is-4-widescreen">
      <StatisticCard class="h-full">
        <template #title>
          Formation{{ customer.total_started_trainings > 1 ? 's' : '' }}
          commencée{{ customer.total_started_trainings > 1 ? 's' : '' }}
        </template>
        <template #default>
          {{ customer.total_started_trainings }}
        </template>
      </StatisticCard>
    </li>
    <li class="column is-6 is-4-widescreen">
      <StatisticCard class="h-full">
        <template #title>
          Formation{{ customer.total_finished_trainings > 1 ? 's' : '' }}
          terminée{{ customer.total_finished_trainings > 1 ? 's' : '' }}
        </template>
        <template #default>
          {{ customer.total_finished_trainings }}
        </template>
      </StatisticCard>
    </li>
    <li class="column is-6 is-4-widescreen">
      <StatisticCard class="h-full">
        <template #title>
          Temps total cumulé
        </template>
        <template #default>
          {{ totalDurationInSeconds | formatSeconds }}
        </template>
      </StatisticCard>
    </li>
    <li class="column is-6 is-4-widescreen">
      <StatisticCard :tooltipOptions="{multilined: false}">
        <template #title>
          Revenus générés
        </template>
        <template
          v-if="customer.total_revenue > 999"
          #tooltipContent
        >
          {{ customer.total_revenue | formatPrice }}
        </template>
        <template #default>
          {{ customer.total_revenue | formatPriceShort }}
        </template>
      </StatisticCard>
    </li>
    <li class="column is-6 is-4-widescreen">
      <StatisticCard :tooltipOptions="{multilined: false}">
        <template #title>
          Revenus à venir
        </template>
        <template
          v-if="customer.total_revenue_pending > 999"
          #tooltipContent
        >
          {{ customer.total_revenue_pending | formatPrice }}
        </template>
        <template #default>
          {{ customer.total_revenue_pending | formatPriceShort }}
        </template>
      </StatisticCard>
    </li>
  </ul>
</template>
