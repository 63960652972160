<script>
import { required } from 'vuelidate/lib/validators';
import APIStore from '@app/services/API/Store';

export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      newTag: '',
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    filteredStoreTags() {
      return this.authStore
        .feature_options
        .crm
        .tags
        .filter((tag) => (
          !this.customer.crm_tags.includes(tag)
          && tag.toString()
            .toLowerCase()
            .indexOf(this.newTag.toLowerCase()) >= 0
        ));
    },
  },
  validations: {
    newTag: {
      required,
    },
  },
  methods: {
    removeTag(tag) {
      const loader = this.$buefy.loading.open({ container: this.$el });
      const tags = [tag];

      APIStore.removeCustomerTag(
        this.authStore.uuid,
        this.customer.uuid,
        { tags },
      )
        .then((data) => {
          this.$store.commit('auth/setStoreFeatureOptions', data);
          this.$store.commit('customers/removeTags', tags);
        })
        .finally(() => loader.close());
    },
    removeStoreTag(tag) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: 'Action définitive',
        message: 'Êtes-vous sûr(e) de vouloir supprimer cette étiquette des suggestions ?',
        focusOn: 'cancel',
        confirmText: 'Oui',
        onConfirm: () => {
          const loader = this.$buefy.loading.open({ container: this.$el });
          const tags = [tag];

          APIStore.removeTag(
            this.authStore.uuid,
            { tags },
          )
            .then((data) => {
              this.$store.commit('auth/setStoreFeatureOptions', data);
            })
            .finally(() => loader.close());
        },
      });
    },
    handle() {
      this.isLoading = true;
      const body = { tags: [this.newTag] };

      APIStore.addCustomerTag(
        this.authStore.uuid,
        this.customer.uuid,
        body,
      )
        .then((data) => {
          this.$store.commit('auth/setStoreFeatureOptions', data);
          this.$store.commit('customers/addTags', [this.newTag]);
          this.newTag = '';
          this.$nextTick(() => this.$v.$reset());
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <section class="card is-relative">
    <header class="card-header">
      <h2 class="card-header-title">
        Gérer les étiquettes (tags)
        <AppFeatureBadge class="ml-2" position="is-right" />
      </h2>
    </header>
    <div class="card-content">
      <GoProWrapper planLock="EXPERT" :hasSep="false">
        <form
          class="block mw-360"
          @submit.prevent="dataIsValid(isLoading) && handle()"
        >
          <b-field
            label="Ajouter une étiquette"
            v-bind="$getErrorProps($v.newTag, ['required'])"
            grouped
          >
            <b-autocomplete
              v-model="newTag"
              :data="filteredStoreTags"
              expanded
              open-on-focus
            >
              <template #default="{ option }">
                {{ option }}
                <button
                  type="button"
                  class="delete is-small ml-5 is-absolute"
                  style="right: 12px"
                  @click.stop="removeStoreTag(option)"
                />
              </template>
            </b-autocomplete>
            <p class="control">
              <b-button
                type="is-primary"
                native-type="submit"
                :loading="isLoading"
              >
                Ajouter
              </b-button>
            </p>
          </b-field>
        </form>
        <div class="tags">
          <span
            v-for="(tag, k) in customer.crm_tags"
            :key="k"
            class="tag is-dark"
          >
            {{ tag }}
            <button
              type="button"
              class="delete is-small ml-5"
              @click="removeTag(tag)"
            />
          </span>
        </div>
      </GoProWrapper>
    </div>
  </section>
</template>
