<script>
import CustomerAddForm from '@app/components/CustomerAddForm.vue';
import CustomerPageTrainingCard from '@app/components/CustomerPageTrainingCard.vue';

export default {
  components: {
    CustomerPageTrainingCard,
  },
  data: () => ({
    trainingIndex: 0,
  }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    currentCustomer() {
      return this.$store.state.customers.current;
    },
    trainingsLength() {
      return this.currentCustomer.customer_trainings.length;
    },
  },
  methods: {
    addToTrainings() {
      const modal = this.$buefy.modal.open({
        component: CustomerAddForm,
        parent: this,
        width: '460px',
        props: { customer: this.currentCustomer },
        events: {
          add: () => {
            modal.close();
            this.$emit('trainings-associated');
          },
        },
      });
    },
    onCustomerUpdate(customer) {
      this.$store
        .commit('customers/updateCustomerTrainingsOnCustomer', customer);
    },
  },
};
</script>

<template>
  <section>
    <header class="mb-3">
      <div
        class="
          is-flex is-justify-content-space-between is-flex-wrap-wrap
          is-align-items-center mb-3
        "
      >
        <h2 class="title is-4 mr-5 mb-2">
          Formations
        </h2>
        <b-button
          type="is-primary"
          icon-left="user-plus"
          @click="addToTrainings"
        >
          <b-tooltip
            label="Inviter l'apprenant dans des formations"
            position="is-left"
          >
            Inviter
            <AppFeatureBadge class="ml-3" />
          </b-tooltip>
        </b-button>
      </div>
      <nav
        v-if="trainingsLength > 1"
        class="buttons is-right"
      >
        <b-button
          type="is-text"
          size="is-small"
          icon-left="chevron-left"
          :disabled="trainingIndex <= 0"
          @click="trainingIndex--"
        >
          <span class="tdecoration-underline">Précédente</span>
        </b-button>
        <b-button
          type="is-text"
          size="is-small"
          icon-right="chevron-right"
          :disabled="trainingIndex >= trainingsLength - 1"
          @click="trainingIndex++"
        >
          <span class="tdecoration-underline">Suivante</span>
        </b-button>
      </nav>
    </header>
    <template v-if="trainingsLength > 0">
      <CustomerPageTrainingCard
        v-for="(one, i) in currentCustomer.customer_trainings"
        :key="one.uuid"
        v-show="trainingIndex == i"
        class="mb-5"
        :customer="currentCustomer"
        :customerTraining="one"
        @customer-update="onCustomerUpdate"
        @delete="$emit('training-delete', one)"
      />
    </template>
    <p v-else class="box is-info">
      Cet apprenant n'a aucune formation active.
    </p>
  </section>
</template>
