<script>
import countries from '@shared/assets/data/countries.json';
import countriesByCode from '@shared/assets/data/countries_by_code.json';
import APIStore from '@app/services/API/Store';

export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  static: {
    countries,
    countriesByCode,
    mobileIcons: {
      UNKNOWN: 'ban',
      DESKTOP: 'desktop',
      TABLET: 'table-alt',
      MOBILE: 'mobile-alt',
      BOT: 'robot',
    },
  },
  data() {
    return {
      isFetching: false,
      connections: null,
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
    load(more = false) {
      if (this.isFetching) {
        return;
      }

      this.isFetching = true;
      let promise;

      if (more) {
        promise = APIStore.getCustomerConnections(
          this.connections.links.next,
        );
      } else {
        promise = APIStore.getCustomerConnections(
          this.authStore.uuid,
          this.customer.uuid,
        );
      }

      promise.then((data) => {
        if (more) {
          console.log(data);
          this.connections = {
            ...data,
            data: [...this.connections.data, ...data.data],
          };
        } else {
          this.connections = data;
        }
      })
        .finally(() => (this.isFetching = false));
    },
  },
  created() {
    this.load();
  },
};
</script>

<template>
  <section class="card is-relative">
    <header class="card-header">
      <h2 class="card-header-title">
        Historique des connexions à la plateforme
      </h2>
    </header>
    <div class="card-content p-3">
      <b-skeleton v-if="!connections" height="200" />
      <ul v-else>
        <li
          v-for="(one, k) in connections.data"
          :key="k"
          class="px-3 py-5 bbottom-grey is-size-8-mobile"
        >
          <p class="flex-between-center">
            <span class="is-size-8 is-size-9-mobile">
              {{
                one.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm')
              }}
            </span>
            <b-tooltip>
              <template #content>
                <span v-t="`devices.${one.device}`" />
              </template>
              <b-icon :icon="$options.static.mobileIcons[one.device]" />
            </b-tooltip>
          </p>
          <p class="mt-3">
            <strong>{{ one.browser }}</strong>
            sur
            <strong>{{ one.os }}</strong>
            <b-tooltip class="ml-2">
              <template #content>
                Adresse IP
              </template>
              ({{ one.ip }})
            </b-tooltip>
          </p>
          <p v-if="!!one.position_country" class="mt-2">
            <img
              class="valign-middle"
              width="32"
              :src="$options.static.countriesByCode[one.position_country].image"
              :alt="$options.static.countries[one.position_country]"
            >
            <span class="is-inline-block valign-middle ml-2">
              {{ $options.static.countries[one.position_country] }}
              <template v-if="!!one.position_city">
                ({{ one.position_city }})
              </template>
            </span>
          </p>
        </li>
      </ul>
      <p
        class="mt-3 has-text-centered"
        v-if="connections && !!connections.links.next"
      >
        <b-button
          type="is-text"
          class="tdecoration-underline"
          @click="load(true)"
        >
          Voir plus d'historique
        </b-button>
      </p>
    </div>

    <b-loading v-if="isFetching" active :is-full-page="false" />
  </section>
</template>
